<template>
    <div>
        <app-layout>
	        <template v-slot:header>
	            <Header :title="$t('prep_exam_definitions')"
	                    :isNewButton="checkPermission('prepexamdefinition_store')"
	                    :isColumns="true"
	                    @new-button-click="showModal('create')"
	                    @filter-div-status="datatable.filterStatus=$event"
	            >
	                <template v-slot:columns>
	                    <div class="mb-1" v-for="(column,key) in datatable.columns">
	                        <b-form-checkbox
	                            :id="'checkbox-'+key"
	                            :name="'checkbox-'+key"
	                            :value="false"
	                            :unchecked-value="true"
	                            v-model="column.hidden"
	                            v-if="column.field!='buttons'"
	                        >
	                            {{ column.label }}
	                        </b-form-checkbox>
	                    </div>
	                </template>
	            </Header>
	        </template>
	        <template v-slot:header-mobile>
		        <HeaderMobile :title="$t('prep_exam_definitions')"
		                      :isNewButton="checkPermission('prepexamdefinition_store')"
		                      :isColumns="true"
		                      @new-button-click="showModal('create')"
		                      @filter-div-status="datatable.filterStatus=$event"
	            >
	                <template v-slot:columns>
	                    <div class="mb-1" v-for="(column,key) in datatable.columns">
	                        <b-form-checkbox
	                            :id="'checkbox-'+key"
	                            :name="'checkbox-'+key"
	                            :value="false"
	                            :unchecked-value="true"
	                            v-model="column.hidden"
	                            v-if="column.field!='buttons'"
	                        >
	                            {{ column.label }}
	                        </b-form-checkbox>
	                    </div>
	                </template>
	            </HeaderMobile>
	        </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6" md="3" lg="2" xl="2">
                        <b-form-group :label="$t('module')">
                            <parameter-selectbox code="prep_modules" size="sm" v-model="datatable.queryParams.filter.module"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="5" lg="5" xl="4">
                         <b-form-group :label="$t('prep_levels')">
                            <parameter-selectbox code="prep_levels" v-model="datatable.queryParams.filter.level"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>
            <CommonModal ref="formModal" size="xl" :onHideOnlyX="true" :footer="true" :footer-class="'justify-content-center'">
                <template v-slot:CommonModalTitle>
                    <div>{{ formProcess=='create' ? $t('new').toUpper():$t('edit').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <ValidationObserver ref="form">
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-4">
                                     <ValidationProvider name="academic_year" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('academic_year')">
                                            <academic-years-selectbox v-model="form.academic_year" :validate-error="errors[0]"></academic-years-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider name="module_id" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('module')">
                                            <module-selectbox :academic_year="form.academic_year" valueType="id" v-model="form.module_id" :validate-error="errors[0]"></module-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider name="level" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('prep_levels')">
                                            <parameter-selectbox code="prep_levels" v-model="form.level" :validate-error="errors[0]"></parameter-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-4">
                                     <ValidationProvider name="exam" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('exam_type')">
                                            <parameter-selectbox code="prep_exams" v-model="form.exam" :validate-error="errors[0]"></parameter-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4">
                                   <ValidationProvider name="number_of_exams" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('number_of_exams')">
                                            <b-form-input type="number"
                                                          step="1"
                                                          min="0"
                                                          v-check-min-max-value
                                                          v-model="form.number_of_exams"
                                                          :state="errors[0] ? false : (valid ? true : null)">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider name="effect_ratio" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('effect_ratio')">
                                            <b-form-input type="number"
                                                          v-model="form.effect_ratio"
                                                          min="0"
                                                          v-check-min-max-value
                                                          :state="errors[0] ? false : (valid ? true : null)">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider name="not_include_calculation" rules="" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('not_include_calculation')">
                                            <b-form-input type="number" step="1"
                                                          v-model="form.not_include_calculation"
                                                          min="0"
                                                          v-check-min-max-value
                                                          :state="errors[0] ? false : (valid ? true : null)">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </ValidationObserver>
                    </div>
                </template>
                <template v-slot:CommonModalFooter>
                    <b-form-group>
                        <b-button variant="primary" @click="formCreate" v-if="formProcess=='create'">{{ $t('save') }}</b-button>
                        <b-button variant="primary" @click="formUpdate" v-if="formProcess=='update'">{{ $t('update') }}</b-button>
                    </b-form-group>
                </template>
            </CommonModal>

        </app-layout>
    </div>
</template>
<script>
    import AppLayout from "@/layouts/AppLayout"
    import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
    import DatatableFilter from "@/components/datatable/DatatableFilter";
    import Datatable from "@/components/datatable/Datatable";
    import PrepExamDefinitionsService from "@/services/PrepExamDefinitionsService";
    import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
    import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
    import ModuleSelectbox from "@/components/interactive-fields/ModuleSelectbox";
    import CommonModal from "@/components/elements/CommonModal";
    import {ValidationProvider, ValidationObserver} from "vee-validate"
    import qs from 'qs'

    export default {
        components: {
            AppLayout,
            Header,
	        HeaderMobile,

            DatatableFilter,
            Datatable,
            CommonModal,
            ValidationProvider,
            ValidationObserver,
            AcademicYearsSelectbox,
            ParameterSelectbox,
            ModuleSelectbox
        },
        metaInfo() {
            return {
                title: this.$t('prep_exam_definitions')
            }
        },
        data() {
            return {
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: '',
                            field: 'buttons',
                            html: true,
                            sortable: false,
                            tdClass: 'p-0 text-center w-40 align-middle',
                            buttons:[
                                {
                                    text: this.$t('edit'),
                                    class: 'ri-search-eye-line align-middle top-minus-1 mr-3 text-muted',
                                    permission: "prepexamdefinition_update",
                                    callback:(row)=>{
                                        this.showModal('update', row.id)
                                    }
                                },
                                {
                                    text: this.$t('delete'),
                                    class: 'ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted',
                                    permission: "prepexamdefinition_delete",
                                    callback:(row)=>{
                                        this.formDelete(row.id)
                                    }
                                }
                            ]
                        },
                        {
                            label: this.$t('id').toUpper(),
                            field: 'id',
                            hidden: true,
                        },
                        {
                            label: this.$t('module').toUpper(),
                            field: 'module',
                            hidden: false,
                        },
                        {
                            label: this.$t('prep_levels').toUpper(),
                            field: 'level',
                            hidden: false,
                        },
                        {
                            label: this.$t('exam_type').toUpper(),
                            field: 'exam',
                            hidden: false,
                        },
                        {
                            label: this.$t('number_of_exams').toUpper(),
                            field: 'number_of_exams',
                            hidden: false,
                            sortable: false,

                        },
                        {
                            label: this.$t('effect_ratio').toUpper(),
                            field: 'effect_ratio',
                            hidden: false,
                            sortable: false,
                        },

                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {},
                        sort: '-id',
                        page: 1,
                        limit: 20
                    }
                },
                formProcess: null,
                formId: null,
                form: {},
            }
        },
        created() {
            this.filterSet();
            this.formClear();
        },
        methods: {
            filterSet(){
                this.datatable.queryParams.filter = {
                    id: null,
                    module_id: null,
                    level: null,
                };
            },
            filterClear() {
                this.filterSet()
                this.getRows();
            },
            filter() {
                this.getRows();
            },
            onAcademicYearChanged(academicYear){
                this.$refs.moduleSelectboxComponent.setAcademicYear(academicYear)
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            changeColumn(key){
                this.datatable.columns[key].hidden = this.datatable.columns[key].hidden==true ? false:true;
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : '';
                this.datatable.queryParams.sort = sortType + params[0].field
                this.getRows();
            },
            getRows() {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                return PrepExamDefinitionsService.getAll(config)
                        .then((response) => {
                            this.datatable.rows = response.data.data
                            this.datatable.total = response.data.pagination.total
                        })
                        .finally(() => {
                            this.datatable.isLoading = false;
                        });
            },

            showModal(process, id=null){
                this.formProcess = process
                this.formId = null;

                this.formClear();
                this.$refs.form.reset();

                if(this.formProcess=='update' && id){
                    PrepExamDefinitionsService.get(id)
                            .then(response => {
                                this.form  = response.data.data;
                                this.formId = id
                            })
                            .catch(e => {
                                this.showErrors(e)
                            })
                }
                this.$refs.formModal.$refs.commonModal.show();
            },
            formClear(){
                this.form ={
                    module_id: null,
                    level: null,
                    exam: null,
                    number_of_exams: null,
                    effect_ratio: null,
                }
            },
            async formCreate(){
                const isValid = await this.$refs.form.validate();
                if (isValid) {
                    let formData = {
                        ...this.form
                    }
                    PrepExamDefinitionsService.store(formData)
                                           .then(response => {
                                               this.$toast.success(this.$t('api.' + response.data.message));
                                               this.getRows();
                                               this.formClear();
                                               this.$refs.form.reset();
                                               this.$refs.formModal.$refs.commonModal.hide()
                                           })
                                           .catch(e => {
                                               this.showErrors(e)
                                           })
                }
            },
            async formUpdate(){
                const isValid = await this.$refs.form.validate();
                if (isValid && this.formId) {
                    let formData = {
                        ...this.form
                    }
                    PrepExamDefinitionsService.update(this.formId, formData)
                                           .then(response => {
                                               this.$toast.success(this.$t('api.' + response.data.message));
                                               this.getRows();
                                               this.formClear();
                                               this.$refs.form.reset();
                                               this.$refs.formModal.$refs.commonModal.hide()
                                           })
                                           .catch(e => {
                                               this.showErrors(e)
                                           })
                }
            },
            formDelete(id){
                this.$swal.fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        PrepExamDefinitionsService.del(id)
                                               .then(response => {
                                                   this.$toast.success(this.$t('api.' + response.data.message));
                                                   this.getRows();
                                                   this.formClear();
                                               })
                                               .catch(error => {
                                                   this.$toast.error(this.$t('api.' + error.data.message));
                                               });
                    }
                })
            },

            showErrors(e){
                if (e.status == '422') {
                    for (const [key, value] of Object.entries(e.data.errors)) {
                        this.$refs.form.errors[key].push(value[0]);
                    }
                }
                else if (e.status == '406') {
                    this.$toast.error(this.$t('api.' + e.data.message));
                }
            }
        }
    }
</script>

